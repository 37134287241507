import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Services from '@compositions/Services'

const BlogPage = () => {
  return (
    <Layout>
      <Services />
    </Layout>
  )
}

export default BlogPage
