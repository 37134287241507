import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

import Body from './components/Body'

const HeroSmall = styled(({ data, className }) => (
  <div className={`${className} position-relative w-100`}>
    <Body data={data} />
  </div>
))`
`

HeroSmall.propTypes = {
  data: object.isRequired
}

HeroSmall.defaultProps = {
  data: {}
}

export default HeroSmall
