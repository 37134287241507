import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Img from 'gatsby-image'

import LargeBox from './components/LargeBox'
import Accordions from './components/Accordions'

import BottomIcon from '@images/bottom-arrow.svg'

const ServicesBlock = styled(({ data, className }) => {
  const servicecategories = data.edges

  return (
    <div className={className}>
      <Container>
        <div className="d-md-block d-none">
          <div className="services-cards">
            <Row>
              {servicecategories.map((item, servIndex) => {
                return (
                  <Col md={3} key={servIndex} className="cards-item">
                    <button onClick={() => scrollTo(`#${item.node.slug}`)}>
                      <Img
                        className="image"
                        fluid={
                          item.node.serviceCategoryImage.sCategoryImage
                            .imageFile.childImageSharp.fluid
                        }
                        alt={
                          item.node.serviceCategoryImage.sCategoryImage.altText
                            ? item.node.serviceCategoryImage.sCategoryImage
                                .altText
                            : item.node.name
                        }
                      />
                      <span>{item.node.name}</span>
                      <BottomIcon />
                    </button>
                  </Col>
                )
              })}
            </Row>
          </div>
          {servicecategories.map((item, servIndex) => {
            const services = item.node.services.edges
            return (
              <div key={servIndex} className="services-category">
                <div
                  id={item.node.slug}
                  className="services-category-link"
                ></div>
                {services.map((item, index) => {
                  return (
                    <LargeBox
                      key={index}
                      index={servIndex >= 2 ? index + 1 : index}
                      data={item.node}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="d-md-none">
          <Accordions data={servicecategories} />
        </div>
      </Container>
    </div>
  )
})`
  //padding-bottom: 29px;

  @media (min-width: 768px) {
    background-color: #fafafa;
  }

  .cards-item {
    @media (max-width: 1200px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .services-cards {
    position: relative;
    margin-bottom: 32px;
    margin-top: -119px;

    @media (min-width: 1200px) {
      margin-bottom: 82px;
    }

    button {
      background-color: #fff;
      border: none;
      display: block;
      padding: 25px 0 35px;
      text-align: center;
      position: relative;
      width: 100%;

      &:after {
        background: #f5c141;
        bottom: 0;
        content: '';
        height: 4px;
        left: 0;
        opacity: 0;
        transition: all 0.3s;
        position: absolute;
        width: 100%;
      }

      @media (min-width: 768px) {
        padding: 25px 0 35px;
      }

      @media (min-width: 1200px) {
        padding: 34px 0 51px;
      }

      &:hover,
      &:focus {
        outline: none;

        span{
          color: #2B58CA;
        }

        &:after{
          opacity: 1;
        }
      }

      .image {
        margin: 0 auto 35px;
        width: 50px;
      }

      span {
        color: #000000;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.03em;
        font-feature-settings: 'pnum' on, 'lnum' on;

        @media (min-width: 1200px) {
          font-size: 24px;
          line-height: 36px;
        }
      }

      svg {
        display: block;
        margin: 16px auto 0;
      }
    }
  }

  .services-category {
    border-top: 2px solid #f5c141;
    padding-bottom: 2px;
    padding-top: 32px;

    @media (min-width: 1200px) {
      padding-top: 40px;
    }

    .services-category-link {
      position: relative;
      top: -95px;
    }
  }
`

ServicesBlock.propTypes = {
  data: object.isRequired
}

ServicesBlock.defaultProps = {
  data: []
}

export default ServicesBlock
