import React from 'react'
import { number, object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'gatsby-background-image'

// components
import Hr from '@components/Hr'

// utils
import colors from '@styles/js/colors'

const LargeBox = styled(({ index, data, className }) => {
  const { title, servicePageFields, serviceTags, featuredImage } = data

  return (
    <div className={className}>
      <div className="d-inline-block w-100">
        <Container>
          <Row>
            <Col className="d-none d-md-block d-xl-none" md={12}>
              <h3
                className="p-0 item-title"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h3>
              <Hr className="mb-4" />
            </Col>
            <Col
              xl={{ order: index % 2 == 0 ? 'first' : 'last' }}
              xs={{ order: 'last' }}
              className={`${index % 2 == 0 ? 'pl-xl-0' : 'pr-xl-0'}`}
            >
              <div className="d-flex flex-column justify-content-center h-100 service-item">
                <div className="d-md-none d-xl-block">
                  <h3
                    className="p-0"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h3>
                  <Hr className="mb-3" />
                </div>
                {servicePageFields.excerpt && (
                  <p className="mb-0">{servicePageFields.excerpt}</p>
                )}
                <div className="service-tags">
                  {serviceTags.edges.map((item, tagIndex) => {
                    return (
                      <div className="service-tags-item" key={tagIndex}>
                        {item.node.name}
                      </div>
                    )
                  })}
                </div>
              </div>
            </Col>
            <Col
              md={4}
              xl={6}
              className={`${index % 2 != 0 ? 'pl-xl-0' : 'pr-xl-0'}`}
            >
              <div
                className="bg-img w-100"
                style={{
                  backgroundImage: `url(${featuredImage.imageFile.childImageSharp.fluid.srcWebp})`
                }}
              ></div>
              {/*
              <Image
                className="bg-img w-100"
                fluid={featuredImage.imageFile.childImageSharp.fluid}
                alt={featuredImage.altText ? featuredImage.altText : title}
                backgroundColor={colors.saffron}
              />
              */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})`
  padding-bottom: 40px;

  @media (max-width: 577px) {
    &:last-child {
      padding-bottom: 0;
    }
  }

  @media (max-width: 1200px) {
    .container {
      padding: 0 0;
    }
  }

  .bg-img {
    background-position: center;
    background-size: cover;
    height: 214px;

    @media (min-width: 768px) {
      height: 210px;
    }

    @media (min-width: 1200px) {
      height: 350px;
    }
  }

  .item-title {
    font-size: 34px;
    font-weight: 900;
    line-height: 130%;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 16px;
    }
  }

  .service-item {
    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.03em;
      margin-top: 16px;

      @media (min-width: 768px) {
        font-size: 34px;
        font-weight: 900;
        line-height: 130%;
        margin-top: 0;
      }
    }

    p {
      color: #767676;
      font-size: 18px;
      line-height: 28px;
    }

    .service-tags {
      margin-bottom: 28px;
      margin-top: 16px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      .service-tags-item {
        background: #fafafa;
        border: 0.5px solid #c5c5c5;
        border-radius: 15px;
        color: #767676;
        display: inline-block;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
        padding: 3px 16px;
        opacity: 0.9;
        margin-bottom: 12px;
        margin-right: 8px;
      }
    }
  }
`

LargeBox.propTypes = {
  data: object.isRequired,
  index: number
}

LargeBox.defaultProps = {
  data: [],
  index: 0
}

export default LargeBox
