import React, { useState } from 'react'
import styled from '@emotion/styled'
import { array } from 'prop-types'
import { Accordion, Card, Button, Container } from 'react-bootstrap'
import Img from 'gatsby-image'

import LargeBox from '../LargeBox'

const Accordions = styled(({ data, className }) => {
  const [currentActiveKey, setCurrentActiveKey] = useState(null)

  const toggleActiveKey = key => {
    setCurrentActiveKey(currentActiveKey === key ? null : key)
  }

  return (
    <div className={`${className}`}>
      <Container className="p-0">
        <Accordion defaultActiveKey="0">
          {data.map((item, index) => {
            const services = item.node.services.edges

            return (
              <Card key={index}>
                <Card.Header
                  className={currentActiveKey === index ? 'active' : null}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={index + 1}
                    onClick={() => toggleActiveKey(index)}
                  >
                    <Img
                      className="image"
                      fluid={
                        item.node.serviceCategoryImage.sCategoryImage.imageFile
                          .childImageSharp.fluid
                      }
                      alt={
                        item.node.serviceCategoryImage.sCategoryImage.altText
                          ? item.node.serviceCategoryImage.sCategoryImage
                              .altText
                          : item.node.name
                      }
                    />
                    <div className="item-title">{item.node.name}</div>
                    <i></i>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={index + 1}>
                  <Card.Body>
                    {services.map((item, index) => {
                      return (
                        <LargeBox
                          key={index}
                          index={1}
                          data={item.node}
                        />
                      )
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })}
        </Accordion>
      </Container>
    </div>
  )
})`
  padding-bottom: 40px;
  padding-top: 28px;
  margin-bottom: 5rem;

  .accordion {
    .card {
      margin-bottom: 16px;

      .card-header {
        border-bottom: 1px solid #C5C5C5;

        &.active{
          button{
            i{
              &:before{
                display: none;
              }
            }
          }
        }

        button {
          display: flex;
          padding: 15px 0 21px 42px;
          position: relative;
          width: 100%;

          .image {
            position: absolute !important;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 34px;
          }

          .item-title {
            color: #000000;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }

          i {
            display: block;
            height: 40px;
            right: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            transition: 0.3s all ease-in;

            &:before,
            &:after {
              background: #2b58ca;
              content: '';
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              transition: 0.3s all ease-in;
            }

            &:before {
              width: 2px;
              height: 24px;
            }

            &:after {
              height: 2px;
              width: 24px;
            }
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
            text-decoration: none;
          }
        }
      }

      .card-body{
        padding: 16px 0 0;
      }
    }
  }
`

Accordions.propTypes = {
  data: array.isRequired
}

Accordions.defaultProps = {
  data: []
}

export default Accordions
