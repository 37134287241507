import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Image from './components/Image'
import Text from './components/Text'
import Link from './components/Link'

import Arrow from '@images/work/arrow.svg'

const QuoteBanner = styled(
  ({ data: { linktext, linkurl, text, alt, image }, fluid, className }) => (
    <div className={`${className} bg-green-light mt-0`}>
      <Container>
        <Row className="align-items-center">
          <Col md>
            <Image fluid={fluid} alt={image.altText ? image.altText : alt} />
          </Col>
          <Col md>
            <div className="mt-3 mt-md-0">
              <Text className="mb-3">{text}</Text>
              <Link to={linkurl}>
                {linktext}
                <Arrow />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
)`
  ${({ overlapped }) =>
    overlapped
      ? `
      margin: 5rem auto 3rem;
      padding-bottom: 3rem;

      @media (min-width: 768px) {
        margin: 3rem auto;
        padding: 3rem 0;
      }

      @media (min-width: 992px) {
        margin-bottom: -3rem;
        padding: 3rem 0 6rem;
      }
    `
      : `
    padding: 3rem 0;
    `}
`

QuoteBanner.propTypes = {
  data: object.isRequired
}

QuoteBanner.defaultProps = {
  data: {}
}

export default QuoteBanner
