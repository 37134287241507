import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Body = styled(({ data: { title, ptext }, className }) => (
  <div className={`${className} w-100 bg-cerulean-blue`}>
    <Container className="h-100">
      <Row className="align-items-end">
        <Col lg={12}>
          <div className="box">
            <h1 className="text-white">{title}</h1>
            {ptext.map((item, index) => (
              <p className="text-white" key={index}>
                {item.paragraph}
              </p>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  </div>
))`
  padding: 40px 0;
  position: relative;
  text-align: center;

  @media (min-width: 768px){
    padding-bottom: 183px;
    padding-top: 64px;
  }

  .box {
  }

  h1 {
    font-size: 28px;
    line-height: 38px;

    @media (min-width: 992px){
      font-size: 48px;
      line-height: 58px;
    }
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

Body.propTypes = {
  data: object.isRequired
}

Body.defaultProps = {
  data: {}
}

export default Body
