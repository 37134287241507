import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'

// components
import SEO from '@components/SEO'
import CarouselBodyButton from '@components/CarouselBodyButton'
import QuoteBanner from './components/QuoteBanner'
import HeroSmall from './components/HeroSmall'
import ServicesBlock from './components/ServicesBlock'
import ProjectForm from '@components/ProjectForm'

// graphql
import { servicesContent } from './modules/graphql'

const Services = styled(({ className }) => {
  const seoData = servicesContent()[0]
  const heroData = servicesContent()[1]
  const quoteBannerData = servicesContent()[2]
  const cardsStandardData = servicesContent()[3]
  const servicesData = servicesContent()[4]
  const projectFormData = servicesContent()[5]

  return (
    <div className={`${className}`}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keyword={seoData.keyword}
      />
      {/* Hero */}
      <div className="d-inline-block w-100">
        <div className="d-inline-block w-100">
          <HeroSmall data={heroData} />
        </div>
      </div>
      {/* Website Development, SEO, Content Management... */}
      <ServicesBlock data={servicesData} />
      {/* Banner */}
      <QuoteBanner
        data={quoteBannerData}
        fluid={quoteBannerData.image.imageFile.childImageSharp.fluid}
        overlapped
      />
      {/* Company, team & work */}
      <div className="d-inline-block w-100">
        <div className="carousel-wrap">
          <Container>
            <Row>
              <Col xs={12} className="bottom-cards">
                <CarouselBodyButton data={cardsStandardData} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ProjectForm data={projectFormData} />
    </div>
  )
})`
  .carousel-wrap {
    padding-bottom: 29px;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }

    @media (min-width: 1440px) {
      padding-bottom: 40px;
    }
  }

  .bottom-cards {
    position: relative;
    top: -40px;
  }
`

export default Services
