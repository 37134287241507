import { useStaticQuery, graphql } from 'gatsby'

export const servicesContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "158", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              __typename
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                title
                description
                keyword
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Herodata {
                title
                image {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                ptext {
                  paragraph
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Quotebannerdata {
                text
                linktext
                linkurl
                alt
                image {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 360, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Cardsstandarddata {
                card {
                  image {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 495, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  imagealt
                  title
                  link
                  text
                }
              }
            }
          }
        }
        serviceCategories(
          first: 10
          where: { orderby: TERM_ID, hideEmpty: true }
        ) {
          edges {
            node {
              name
              slug
              serviceCategoryImage {
                sCategoryImage {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 50, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              services(where: { orderby: { field: DATE, order: ASC } }) {
                edges {
                  node {
                    title
                    servicePageFields {
                      excerpt
                    }
                    featuredImage {
                      altText
                      sourceUrl
                      mediaItemId
                      modified
                      imageFile {
                        childImageSharp {
                          fluid(maxWidth: 540, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    serviceTags {
                      edges {
                        node {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        themeSettings {
          settingFields {
            project {
              submitbutton
              title
              image {
                mobile {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
                tablet {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
                desktop {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return [
    data.wpgraphql.page.AllPageElements.pageelements[0],
    data.wpgraphql.page.AllPageElements.pageelements[1],
    data.wpgraphql.page.AllPageElements.pageelements[2],
    data.wpgraphql.page.AllPageElements.pageelements[3],
    data.wpgraphql.serviceCategories,
    data.wpgraphql.themeSettings.settingFields.project
  ]
}
